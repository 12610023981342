
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import profileService from './../../../services/profile'
import searchService from '../../../services/filter'
import commonServices from '../../../services/common'

export default defineComponent({
  components: {
    HomeOutlined
  },
  setup () {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const searchString = ref<string>('')
    const candidates = ref()
    const candidateData = ref()
    const onSearch = (searchValue: string) => {
      console.log('use value', searchValue)
      candidates.value = searchService.search(candidateData.value, searchValue)
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const columns = [
      {
        title: '#',
        dataIndex: 'key',
        key: '_id',
        onFilter: (value, record) => record._id.indexOf(value) === 0,
        sorter: (a, b) => a._id.length - b._id.length,
        sortDirections: ['descend', 'ascend'],
        width: '5%',
        slots: { customRender: 'sNo' }
      },
      {
        title: 'Candidate Name',
        key: 'name',
        onFilter: (value, record) => record.indexOf(value) === 0,
        sorter: (a, b) => a.first_name.localeCompare(b.first_name.length),
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'nameSection' }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        onFilter: (value, record) => record.indexOf(value) === 0,
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortDirections: ['descend', 'ascend']
      }
    ]
    const getCandidates = async () => {
      try {
        const profile = commonServices.getCurrentProfile()
        const responce = await profileService.getCourseCompletedCandidates(profile._id)
        candidates.value = responce.data
        candidateData.value = responce.data
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      getCandidates()
    })
    return {
      searchString,
      onSearch,
      columns,
      candidates,
      loginAs
    }
  }
})
