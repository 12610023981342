<template>
  <div class="main">
    <div class="user content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Certificate</a-breadcrumb-item>
      </a-breadcrumb>

      <!-- <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div></div>
          <div class="add">
          </div>
        </a-col>
      </a-row> -->
      <a-row class="user-lists">
        <div class="body">
          <a-row class="filter">
            <div class="title">Certificate</div>
            <a-input-search
              v-model:value="searchString"
              placeholder="Search here ..."
              @search="onSearch"
            />
          </a-row>
          <div class="users">
            <a-table
              :columns="columns"
              :data-source="candidates"
              class="ant-table-striped"
              :rowClassName="
                (record, index) => (index % 2 === 1 ? 'table-striped' : null)
              "
              :pagination="{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
              }"
              rowKey="_id"
            >
              <template #sNo="{  index }">
                <span>{{index + 1}}</span>
              </template>
              <template #nameSection="{ record }">
                <router-link :to="'/client/certificate/list/'+record._id+loginAs">
                  <div class="profileimg">
                    <img
                      :src="
                        record.profile_image
                          ? record.profile_image
                          : 'https://ui-avatars.com/api/?background=FFC788&name=' +
                            record.first_name +
                            '&rounded=true&color=ffffff'
                      "
                    />
                    <span class="name">{{ record.first_name }} {{ record.last_name }}</span>
                  </div>
                </router-link>
              </template>
            </a-table>
          </div>
        </div>
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import profileService from './../../../services/profile'
import searchService from '../../../services/filter'
import commonServices from '../../../services/common'

export default defineComponent({
  components: {
    HomeOutlined
  },
  setup () {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const searchString = ref<string>('')
    const candidates = ref()
    const candidateData = ref()
    const onSearch = (searchValue: string) => {
      console.log('use value', searchValue)
      candidates.value = searchService.search(candidateData.value, searchValue)
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const columns = [
      {
        title: '#',
        dataIndex: 'key',
        key: '_id',
        onFilter: (value, record) => record._id.indexOf(value) === 0,
        sorter: (a, b) => a._id.length - b._id.length,
        sortDirections: ['descend', 'ascend'],
        width: '5%',
        slots: { customRender: 'sNo' }
      },
      {
        title: 'Candidate Name',
        key: 'name',
        onFilter: (value, record) => record.indexOf(value) === 0,
        sorter: (a, b) => a.first_name.localeCompare(b.first_name.length),
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'nameSection' }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        onFilter: (value, record) => record.indexOf(value) === 0,
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortDirections: ['descend', 'ascend']
      }
    ]
    const getCandidates = async () => {
      try {
        const profile = commonServices.getCurrentProfile()
        const responce = await profileService.getCourseCompletedCandidates(profile._id)
        candidates.value = responce.data
        candidateData.value = responce.data
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      getCandidates()
    })
    return {
      searchString,
      onSearch,
      columns,
      candidates,
      loginAs
    }
  }
})
</script>

<style lang="scss">
.user {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .add {
      float: right;
      .ant-btn {
        background-color: #7EAF1A;
        border-color: #7EAF1A;
        border-radius: 5px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
  .user-lists {
    padding: 10px;
    .body {
      background: #ffffff;
      min-height: 200px;
      box-shadow: 0px 5px 10px #00000003;
      border-radius: 8px;
      padding: 15px;
      width: 100%;
      .filter {
        display: flex;
        justify-content: space-between;
        margin: 10px 0 10px 0;
        .title {
          color: #707070;
          float: left;
          font-size: 20px;
          text-transform: capitalize;
          font-family: "TT Norms Pro Medium";
        }
        .noofentries {
          display: flex;
          align-items: center;
          .ant-select {
            margin: 0 5px;
          }
        }
        .ant-input-affix-wrapper {
          width: 200px;
        }
        .ant-select,
        .ant-btn {
          float: left;
        }
        .ant-btn {
          background: #7EAF1A;
          border-color: #7EAF1A;
          border-radius: 20px;
          font-family: "TT Norms Pro Medium";
        }
      }
      .users {
        margin-top: 30px;
        font-family: "TT Norms Pro Medium";
        .profileimg {
          display: flex;
          align-items: center;
          img {
            width: 35px;
            height: 35px;
            line-height: 35px;
            font-size: 20px;
            transform: scale(0.8);
            border-radius: 50%;
            margin: 0 5px 0 0;
          }
          span {
            color: #727cf5;
            text-transform: capitalize;
            font-family: "TT Norms Pro Medium";
          }
        }
        .actionblock {
          img {
            width: 30px;
            height: 30px;
            cursor: pointer;
            margin: 0 5px;
          }
        }
        .ant-table {
          color: #646464;
          .ant-table-thead {
            tr {
              th {
                &:first-child {
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                }
                &:last-child {
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                }
              }
            }
          }
          .ant-table-tbody {
            tr {
              td {
                &:nth-child(3) {
                  font-family: "TT Norms Pro Regular";
                }
              }
            }
          }
        }
        .ant-pagination {
          .ant-pagination-options {
            // display: none;
          }
          .ant-pagination-prev .ant-pagination-item-link,
          .ant-pagination-next .ant-pagination-item-link {
            border-radius: 50%;
            background-color: #ededed;
            border: none;
          }
          .ant-pagination-item {
            background-color: #ededed;
            margin-right: 0px;
            border: none;
            font-family: "TT Norms Pro Regular";
          }
          li:nth-child(2) {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }
          li:nth-last-child(3n) {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }
          .ant-pagination-next {
            margin-left: 8px;
          }
          .ant-pagination-item-active {
            background-color: #ededed;
            a {
              background: #6f64f8;
              color: #fff;
              border-radius: 50%;
            }
          }
        }
        .totalentries {
          font-family: "TT Norms Pro Regular";
          text-align: left;
          margin: -40px 0 0 0;
          color: #646464;
          p {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #f7fafc;
}
</style>
